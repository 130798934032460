
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { GeneralProvider } from '@/context/GeneralContext';
import '@/assets/sass/index.scss';

function App({ Component, pageProps }) {
  return (
    <GeneralProvider value={{ ...pageProps?.data?.general }}>
      <Component {...pageProps} />
    </GeneralProvider>
  );
}

const __Page_Next_Translate__ = App;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  